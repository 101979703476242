import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Constants from '../constants'
import { HtmlContent } from '../html-content'
import { useSafeUpdateQuery } from '../use-safe-update-query'

import {
  DescriptionHeadingTypography,
  DescriptionTypography,
  DesktopHeroImage,
  Divider,
  RaffleLogo,
} from './components'
import { RaffleButton } from './raffle-button'
import { RaffleCard } from './raffle-card'
import { RaffleFAQSection } from './faq-section'
import { RaffleTimer } from './raffle-timer'
import { useRaffleCampaigns } from './use-fetch-raffle-campaigns'

const MAX_CONTENT_WIDTH = '540px'

const Overlay = styled.div`
  ${props =>
    css({
      width: '372px',
      height: '512px',
      backgroundImage: `url(${props.background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: '125% 251px',
      pointerEvents: 'none',
      display: 'none',
      position: 'absolute',
      right: '0px',
      bottom: '0px',
    })};

  @media (max-width: 580px) {
    display: block;
  }
`

function WelcomeSection(props) {
  const translate = Herz.I18n.useTranslate()
  const { authenticated } = Herz.Auth.useSession()
  const loginQuery = useSafeUpdateQuery({ login: 'me' })
  const registerQuery = useSafeUpdateQuery({ register: 'me' })

  return (
    <Common.Box
      width="100%"
      backgroundImage={[
        `url(${translate('raffle-page.section-1.background-mobile')})`,
        `url(${translate('raffle-page.section-1.background')})`,
      ]}
      backgroundPosition="center center, center center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      px={2}
      position="relative"
      pb="77px"
    >
      <Overlay
        background={translate(
          'raffle-page.section-1.background-mobile.overlay'
        )}
      />
      <Common.Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        maxWidth={MAX_CONTENT_WIDTH}
        pt="24px"
        zIndex="9"
      >
        <RaffleLogo
          background={translate('raffle-page.section-1.logo')}
          backgroundMobile={translate('raffle-page.section-1.logo-mobile')}
        />
        <Common.Box pt="24px" width="100%">
          <DesktopHeroImage
            background={translate('raffle-page.section-1.hero-image')}
          />
        </Common.Box>
        <Common.Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          width="100%"
          pt="24px"
          px={['10px', '0px']}
        >
          <DescriptionTypography>
            <HtmlContent
              html={{
                __html: translate('raffle-page.section-1.description'),
              }}
            />
          </DescriptionTypography>
          <Common.Box pt={2}>
            <DescriptionHeadingTypography>
              {translate('raffle-page.section-1.rules.title')}
            </DescriptionHeadingTypography>
          </Common.Box>
          <Common.Box pt={[0, 2]}>
            <DescriptionTypography>
              <HtmlContent
                html={{
                  __html: translate('raffle-page.section-1.rules.description'),
                }}
              />
            </DescriptionTypography>
          </Common.Box>
        </Common.Box>

        <Common.Box
          display="flex"
          width="100%"
          pt="48px"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <ReactRouter.Link
            to={
              authenticated
                ? `/casino/games/${props.gameId}`
                : `?${registerQuery}`
            }
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <RaffleButton type="primary">
              {authenticated
                ? translate('raffle-page.section-1.buttons.play')
                : translate('raffle-page.section-1.buttons.register')}
            </RaffleButton>
          </ReactRouter.Link>
          <Common.Box
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
            pt={1}
          >
            {authenticated ? (
              <RaffleButton type="secondary" disabled>
                {translate('raffle-page.section-1.tickets-total', {
                  tickets: props.tickets,
                })}
              </RaffleButton>
            ) : (
              <ReactRouter.Link
                to={`?${loginQuery}`}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <RaffleButton type="primary">
                  {translate('raffle-page.section-1.login')}
                </RaffleButton>
              </ReactRouter.Link>
            )}
          </Common.Box>
        </Common.Box>
      </Common.Box>
      <Divider
        src={translate('raffle-page.divider')}
        alt="divider"
        width="100%"
      />
    </Common.Box>
  )
}

WelcomeSection.propTypes = {
  tickets: PropTypes.number,
  gameId: PropTypes.string,
}

function RaffleContent(props) {
  const currentCampaign =
    props.campaigns?.find(campaign => campaign.status === 'running') || []

  const translate = Herz.I18n.useTranslate(
    () => [
      ...(currentCampaign?.rewards?.map(
        reward =>
          `raffle-page.section-2.reward.${currentCampaign.key}.${reward.kind}.${reward.amount}`
      ) || []),
    ],
    [currentCampaign]
  )

  const { authenticated } = Herz.Auth.useSession()

  const loginQuery = useSafeUpdateQuery({ login: 'me' })
  const depositQuery = useSafeUpdateQuery({
    wallet: Constants.TransactionType.DEPOSIT,
  })

  return (
    <Common.Box
      background="#1b0434"
      width="100%"
      height="100%"
      minHeight="100vh"
      display="flex"
      justifyContent="center"
    >
      <Common.Box
        width="100%"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        maxWidth={MAX_CONTENT_WIDTH}
        pt="64px"
        pb="80px"
        px={1}
      >
        <Common.Box
          display="flex"
          justifyContent={['center', 'flex-start']}
          pt="32px"
          width="100%"
        >
          <DescriptionHeadingTypography>
            {translate('raffle-page.section-2.header')}
          </DescriptionHeadingTypography>
        </Common.Box>
        <Common.Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          pb="40px"
          pt={['12px', '24px']}
          width="100%"
          alignItems={['center', 'flex-start']}
        >
          <DescriptionTypography>
            <HtmlContent
              html={{
                __html: translate('raffle-page.section-2.description'),
              }}
            />
          </DescriptionTypography>
          <Common.Box pl={3} as="ul" pt={1}>
            {currentCampaign?.rewards?.map(reward => (
              <Common.Box
                as="li"
                key={`${reward.amount}${reward.kind}`}
                style={{
                  listStyle: 'disc',
                }}
              >
                <DescriptionTypography>
                  <HtmlContent
                    html={{
                      __html: translate(
                        `raffle-page.section-2.reward.${currentCampaign.key}.${reward.kind}.${reward.amount}`,
                        {
                          amount: reward.amount,
                          depositAmountFrom:
                            reward.deposit_amount_from_cents / 100,
                          depositAmountTo: reward.deposit_amount_to_cents / 100,
                        }
                      ),
                    }}
                  />
                </DescriptionTypography>
              </Common.Box>
            ))}
          </Common.Box>
        </Common.Box>
        <Common.Box
          alignItems="center"
          pb="40px"
          display="flex"
          flexDirection={['column', 'row']}
        >
          <Common.Box
            as="p"
            pr={['0px', 1]}
            pb={[1, '0px']}
            fontSize={['12px', '18px']}
            fontWegith="600"
            fontFamily='"Source Sans 3", serif'
            style={{ textTransform: 'uppercase' }}
          >
            {translate('raffle-page.section-2.raffle-drawn-in')}
          </Common.Box>
          <RaffleTimer timestamp={currentCampaign?.activeTo} />
        </Common.Box>
        <Common.Box width="100%" pb="40px">
          <ReactRouter.Link
            to={authenticated ? `?${depositQuery}` : `?${loginQuery}`}
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <RaffleButton>
              {translate('raffle-page.section-2.deposit')}
            </RaffleButton>
          </ReactRouter.Link>
        </Common.Box>
        <Common.Box width="100%" pt="40px" pb="40px">
          <Common.Box display="flex" justifyContent="center" width="100%">
            <DescriptionHeadingTypography>
              {translate('raffle-page.section-2.raffle-cards.header')}
            </DescriptionHeadingTypography>
          </Common.Box>
          <Common.Box
            style={{ gap: '40px' }}
            py={1}
            width="100%"
            display="flex"
            flexDirection="column"
          >
            {props.campaigns
              ?.sort((a, b) => new Date(a.activeFrom) - new Date(b.activeFrom))
              .map((campaign, i) => (
                <RaffleCard
                  index={i}
                  key={campaign?.key}
                  id={campaign?.key}
                  activeFrom={campaign?.activeFrom}
                  activeTo={campaign?.activeTo}
                  status={campaign?.status}
                  winnerId={campaign?.winnerId}
                  isWinner={campaign?.isWinner}
                  isFinal={Boolean(props.campaigns.length === i + 1)}
                />
              ))}
          </Common.Box>
        </Common.Box>
        <RaffleFAQSection />
        <Common.Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="column"
          fontSize="16px"
        >
          <Common.Box>{translate('raffle-page.section-2.help')}</Common.Box>
          <Common.Box
            pt={0}
            style={{ textDecoration: 'underline' }}
            as="a"
            href={'mailto:support@winningdays.com'}
          >
            {translate('raffle-page.section-2.contact')}
          </Common.Box>
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

RaffleContent.propTypes = {
  campaigns: PropTypes.array.isRequired,
}

export function Raffle() {
  const { data, invalidate } = useRaffleCampaigns()

  React.useEffect(() => {
    invalidate()
  }, [])

  return (
    <Common.Box
      minHeight="100vh"
      width="100%"
      display="flex"
      justifyContent="center"
    >
      <Common.Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <WelcomeSection gameId={data?.game_id} tickets={data?.tickets} />
        <RaffleContent campaigns={data?.campaigns} />
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'raffle-page.section-1.background-mobile',
    'raffle-page.section-1.background',
    'raffle-page.section-1.background-mobile.overlay',
    'raffle-page.section-1.logo',
    'raffle-page.section-1.logo-mobile',
    'raffle-page.section-1.hero-image',
    'raffle-page.section-1.description',
    'raffle-page.section-1.rules.title',
    'raffle-page.section-1.rules.description',
    'raffle-page.section-1.buttons.play',
    'raffle-page.section-1.buttons.register',
    'raffle-page.section-1.tickets-total',
    'raffle-page.section-1.login',
    'raffle-page.divider',
    'raffle-page.section-2.header',
    'raffle-page.section-2.description',
    'raffle-page.section-2.reward',
    'raffle-page.section-2.raffle-drawn-in',
    'raffle-page.section-2.deposit',
    'raffle-page.section-2.raffle-cards.header',
    'raffle-page.section-2.help',
    'raffle-page.section-2.contact',
    `raffle-page.faq.1.title`,
    `raffle-page.faq.2.title`,
    `raffle-page.faq.3.title`,
    `raffle-page.faq.4.title`,
    `raffle-page.faq.5.title`,
    `raffle-page.faq.6.title`,
    `raffle-page.faq.7.title`,
    `raffle-page.faq.8.title`,
    `raffle-page.faq.9.title`,
    `raffle-page.faq.10.title`,
    `raffle-page.faq.11.title`,
    `raffle-page.faq.1.content`,
    `raffle-page.faq.2.content`,
    `raffle-page.faq.3.content`,
    `raffle-page.faq.4.content`,
    `raffle-page.faq.5.content`,
    `raffle-page.faq.6.content`,
    `raffle-page.faq.7.content`,
    `raffle-page.faq.8.content`,
    `raffle-page.faq.9.content`,
    `raffle-page.faq.10.content`,
    `raffle-page.faq.11.content`,
    'raffle-page.faq-terms.terms',
    'raffle-page.faq-terms.faq',
    `raffle-page.terms-and-conditions.1.title`,
    `raffle-page.terms-and-conditions.2.title`,
    `raffle-page.terms-and-conditions.3.title`,
    `raffle-page.terms-and-conditions.4.title`,
    `raffle-page.terms-and-conditions.1.content`,
    `raffle-page.terms-and-conditions.2.content`,
    `raffle-page.terms-and-conditions.3.content`,
    `raffle-page.terms-and-conditions.4.content`,
  ],
  Raffle
)

export default Raffle
