import * as React from 'react'
import PropTypes from 'prop-types'

import css from '@styled-system/css'
import styled from '@emotion/styled'

const PrimaryButton = styled.button`
  ${css({
    border: '6px solid white',
    background: '#00E700',
    boxShadow: '-15px -13px 0px 0px rgba(0, 4, 255, 0.07) inset',
    color: 'white',
    cursor: 'pointer',
    fontSize: ['18px', '32px'],
    fontWeight: '900',
    fontFamily: 'Source Sans 3',
    borderRadius: ['10px', '20px'],
    textShadow: '-2px 3px 2px #002AFC40',
    maxWidth: ['200px', '350px'],
    width: '100%',
    padding: '7px',
  })};
`

const SecondaryButton = styled.button`
  ${props =>
    css({
      cursor: props.disabled ? 'default' : 'pointer',
      pointerEvents: props.disabled ? 'none' : 'auto',
      borderRadius: ['10px', '20px'],
      border: '6px solid white',
      background: 'rgba(0, 231, 0, 0.20)',
      boxShadow: '-15px -13px 0px 0px rgba(0, 4, 255, 0.07) inset',
      color: 'white',
      fontSize: ['18px', '32px'],
      fontWeight: '900',
      fontFamily: 'Source Sans 3',
      textShadow: '-2px 3px 2px #002AFC40',
      maxWidth: ['200px', '350px'],
      width: '100%',
      padding: '7px',
    })};
`

export function RaffleButton(props) {
  if (props.type === 'primary') {
    return (
      <PrimaryButton
        {...props}
        disalbed={props.disabled}
        onClick={props.onClick}
      >
        {props.children}
      </PrimaryButton>
    )
  }

  if (props.type === 'secondary') {
    return (
      <SecondaryButton
        {...props}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.children}
      </SecondaryButton>
    )
  }

  return (
    <PrimaryButton {...props} disabled={props.disabled} onClick={props.onClick}>
      {props.children}
    </PrimaryButton>
  )
}

RaffleButton.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}
